import styled from "styled-components/macro";
import { DropdownContainer as MegaDropdown } from "./MegaMenu/styles";
import { DropdownContainer as SubDropdown } from "./Submenu/styles";

interface Props {
  $primary?: string;
  $secondary?: string;
  $tertiary?: string;
  $length?: number;
  $type?: string;
}

export const StyledNavbar = styled.div<Props>`
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0px;
  font-size: 1rem;
  font-family: Fjalla One;
  background-color: ${({ $primary }) => $primary};
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 4px solid ${({ $tertiary }) => $tertiary};
  filter: drop-shadow(0 0.05rem 0.15rem black);
  text-align: center;
  z-index: 999;

  @media screen and (max-width: 1149px) {
    display: none;
  }

  @media screen and (max-width: 1366px) {
    && {
      font-size: 0.85rem;
    }
  }
`;

export const Navigation = styled.nav`
  margin-top: 2rem;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NavDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  h1 {
    text-transform: uppercase;
    font-size: 1rem;
    font-family: Fjalla One;
    letter-spacing: 0.1rem;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }

  img {
    width: 75px;
    display: inline;
  }

  @media screen and (max-width: 1366px) {
    h1 {
      font-size: 0.85rem;
    }

    img {
      width: 60px;
    }
  }
`;

export const DropdownArrow = styled.span`
  display: inline-block;
  border-top: 4px solid #f0f0f0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  margin-left: 5px;
  vertical-align: middle;
`;

export const ListItem = styled.li`
  text-align: center;
  display: block;
  padding-top: 0.4rem;
  padding-bottom: 2.4rem;
  letter-spacing: 0.05rem;

  @media screen and (max-width: 1366px) {
    padding-bottom: 2.5rem;
  }
`;

export const NavListItem = styled(ListItem)<Props>`
  ${({ $type }) => $type === "submenu" && "position: relative;"}
  margin-left: -0.1rem;

  &:not(:first-child)::before {
    content: '';
    border-left: 1px solid #f0f0f0;
  }
  
  &:hover,
  &:focus {
    background: #f0f0f0;
    color: ${({ $primary }) => $primary};
    border-radius: 5px 5px 0 0;

    a {
      cursor: pointer;
    }

    ${DropdownArrow} {
      border-top: 4px solid ${({ $primary }) => $primary};
      transform: rotate(-180deg);
    }

    ${MegaDropdown}, ${SubDropdown} {
      transition: all 0.25s ease-in-out;
      opacity: 1;
      visibility: visible;
    }

    ${MegaDropdown} {
      top: 95px;
      @media screen and (max-width: 1366px) {
        top: 90px;
      }
    }

    ${SubDropdown} {
      top: 60px;
    }
`;
