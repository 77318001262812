import React, { useState, useEffect } from "react";
import Formsy from "formsy-react";
import OCVTextInput from "./OCVFormComponents/OCVTextInput";
import { Row, Col } from "reactstrap";
import OCVTextArea from "./OCVFormComponents/OCVTextArea";
import OCVDateTimePicker from "./OCVFormComponents/OCVDateTimePicker";
import OCVDropdown from "./OCVFormComponents/OCVDropdown";
import OCVRadio from "./OCVFormComponents/OCVRadio";
import OCVSwitch from "./OCVFormComponents/OCVSwitch";
import OCVGPS from "./OCVFormComponents/OCVGPS";
import OCVFileUpload from "./OCVFormComponents/OCVFileUpload";
import ReactLoading from "react-loading";
import Paper from "@material-ui/core/Paper/Paper";
import OCVFormSlider from "./OCVFormComponents/OCVFormSlider";
import { API } from "aws-amplify";
import "./OCVForm.css";

export default function OCVForm(props: any) {
  const [canSubmit, setCanSubmit] = useState(false);
  const [formData, setFormData] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const { link } = props;
    try {
      fetch(link, { mode: "cors" })
        .then((response) => response.json())
        .then((data) => {
          setFormData(data);
        });
    } catch (e) {
      console.log(e);
    }
  }, [props]);

  const disableButton = () => {
    setCanSubmit(false);
  };
  const enableButton = () => {
    setCanSubmit(true);
  };
  const submit = (model: any) => {
    setIsSubmitting(true);
    API.post("ocvapps", "/form/submission", {
      body: {
        appID: formData.appID,
        data: {
          formID: props.formID,
          formData: model,
        },
      },
      headers: {
        "x-api-key": "AJgsD4mQAY95dQiaJecac3WBvEFlqnvn3vAxI93f",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.response.statusCode === 200) {
          setIsSubmitting(false);
          setIsSubmitted(true);
        } else {
          setIsSubmitting(false);
          setIsSubmitted(false);
          alert(
            "There has been a problem with your form submission. Contact the Web Administrator for help."
          );
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsSubmitted(false);
        alert(error);
      });
  };
  const buildSection = (section: any, index: string | number | undefined) => {
    let formSection: JSX.Element[] = [];
    for (let i = 0; i < section.fields.length; i++) {
      switch (true) {
        case section.fields[i].type === 0 && section.fields[i].subtype === 0:
          formSection.push(
            <OCVTextInput
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 0 && section.fields[i].subtype === 1:
          formSection.push(
            <OCVTextInput
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
              validations="isEmail"
              validationError="This is not a valid email!"
            />
          );
          break;

        case section.fields[i].type === 0 && section.fields[i].subtype === 2:
          formSection.push(
            <OCVTextInput
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
              validationError="This is not a valid number!"
            />
          );
          break;

        case section.fields[i].type === 0 && section.fields[i].subtype === 3:
          formSection.push(
            <OCVTextInput
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
              validationError="This is not a valid phone number!"
            />
          );
          break;

        case section.fields[i].type === 0 && section.fields[i].subtype === 7:
          formSection.push(
            <OCVTextInput
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
              validations={"isNumeric"}
              validationError="This is not a valid zip code!"
            />
          );
          break;

        case section.fields[i].type === 1 && section.fields[i].subtype === 0:
          formSection.push(
            <OCVTextArea
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 2:
          formSection.push(
            <OCVDateTimePicker
              placeholderText={"Select a Time"}
              key={section.fields[i].fieldID + i}
              format={"h:mm a"}
              label={section.fields[i].title}
              name={section.fields[i].fieldID}
              showTime={true}
              showTimeOnly={true}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 3:
          formSection.push(
            <OCVDateTimePicker
              placeholderText={"Select a Date"}
              key={section.fields[i].fieldID + i}
              format={"MMMM d, yyyy"}
              label={section.fields[i].title}
              name={section.fields[i].fieldID}
              showTime={false}
              showTimeOnly={false}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 4:
          formSection.push(
            <OCVDropdown
              elements={section.fields[i].elements}
              key={section.fields[i].fieldID + i}
              multiple={section.fields[i].multi === 1}
              label={section.fields[i].title}
              name={section.fields[i].fieldID}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 5:
          formSection.push(
            <OCVDropdown
              elements={section.fields[i].elements}
              key={section.fields[i].fieldID + i}
              multiple={section.fields[i].multi === 1}
              label={section.fields[i].title}
              name={section.fields[i].fieldID}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 6:
          formSection.push(
            <OCVRadio
              key={section.fields[i].fieldID + i}
              elements={section.fields[i].elements}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 7:
          formSection.push(
            <OCVDateTimePicker
              placeholderText={"Select a Date & Time"}
              key={section.fields[i].fieldID + i}
              format={"MMMM d, yyyy h:mm a"}
              label={section.fields[i].title}
              name={section.fields[i].fieldID}
              showTime={true}
              showTimeOnly={false}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 8:
          formSection.push(
            <OCVSwitch
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 9:
          formSection.push(
            <OCVGPS
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              required={section.fields[i].optional !== 1}
            />
          );
          break;

        case section.fields[i].type === 10:
          formSection.push(
            <OCVFormSlider
              key={section.fields[i].fieldID + i}
              name={section.fields[i].fieldID}
              label={section.fields[i].title}
              step={section.fields[i].intOnly === 1 ? 1 : 0.1}
              max={section.fields[i].max}
              min={section.fields[i].min}
            />
          );
          break;
      }
    }
    return (
      <Paper
        key={index}
        className={
          "OCVFormPaperOne " + props.viewData?.additionalTailwindStyles
        }
      >
        <h5>{section.title}</h5>
        {formSection}
      </Paper>
    );
  };

  if (formData === null) {
    return (
      <div className="OCVFormDiv">
        <ReactLoading
          className="loading-centered"
          type={"bars"}
          color={"#000"}
          height={"10%"}
          width={"10%"}
        />
      </div>
    );
  } else {
    const config = props?.viewData;
    let backgroundImageConfig = undefined;

    if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
      backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
    else if (config?.rgba1 && config?.rgba2)
      backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
    else if (config?.backgroundImageURL)
      backgroundImageConfig = `url(${config.backgroundImageURL})`;

    return (
      <div
        id={props.anchorID}
        className={`bg-cover ${config?.additionalTailwindStyles}`}
        style={{
          backgroundColor: "#eee",
          backgroundImage: backgroundImageConfig,
          flexGrow: 2,
        }}
      >
        <Row className="OCVFormRow">
          <Col xs={12} sm={8}>
            {formData.leaderText ? (
              <Paper className="OCVFormPaperOne">
                <p dangerouslySetInnerHTML={{ __html: formData.leaderText }} />
              </Paper>
            ) : (
              ""
            )}
            {!isSubmitting && !isSubmitted ? (
              <Formsy
                onValidSubmit={submit}
                onValid={enableButton}
                onInvalid={disableButton}
              >
                {formData.sections.map(
                  (section: any, index: string | number | undefined) =>
                    buildSection(section, index)
                )}
                <Paper className="OCVFormPaperOne">
                  {formData.images ? (
                    <OCVFileUpload
                      name={"images"}
                      label={
                        "Select up to 3 Images - (File types accepted: png | jpg | jpeg)"
                      }
                    />
                  ) : (
                    " "
                  )}
                  <button
                    type="submit"
                    className={"btn btn-success"}
                    disabled={!canSubmit}
                  >
                    Submit
                  </button>
                </Paper>
              </Formsy>
            ) : isSubmitting ? (
              <div className="OCVFormDiv">
                <ReactLoading
                  className="loading-centered"
                  type={"bars"}
                  color={"#000"}
                  height={"10%"}
                  width={"10%"}
                />
              </div>
            ) : (
              ""
            )}
            {isSubmitted ? (
              <Paper className="OCVFormPaperTwo">
                <h2 className="OCVFormH2">{props.submissionText}</h2>
              </Paper>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
